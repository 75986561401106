<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark text-white font-bold w-full fixed md:static"
		>
			<span class="text-white ml-2">Resumen</span>
			<!-- <div>
        <span>Filtar por: </span>
        <select
          id="select"
          class="py-1 h-8 w-40 rounded-lg bg-verdedark border border-white text-white font-bold px-4 focus:outline-none"
        >
          <option value="">Todos</option>
          <option value="">Completas</option>
          <option value="">Incompletas</option>
          <option value="">Enviadas</option>
        </select>
      </div> -->
			<div class="flex items-center justify-center">
				<router-link to="/Cotizar"
					><button
						class="font-bold py-2 px-4 inline-flex items-center focus:outline-none"
					>
						<svg
							id="Capa_1"
							enable-background="new 0 0 551.13 551.13"
							height="512"
							viewBox="0 0 551.13 551.13"
							width="512"
							class="h-6 w-6 mr-2 fill-current text-white"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
							/>
							<path
								d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
							/>
						</svg>
						<span class="underline">Realizar cotización</span>
					</button></router-link
				>
			</div>
		</div>
		<!-- <div class="flex items-center justify-center mb-4 mt-20 md:mt-0">
      <span
        ><datepicker
          v-model="date"
          class="border border-azul rounded-lg"
          clear-button
      /></span>
    </div> -->
		<table id="pedidos" class="table-auto mx-auto mt-10 mb-8">
			<thead>
				<tr>
					<th></th>
					<th class="border-2 text-center px-4 py-2">Cotizacion</th>
					<th class="border-2 text-center px-4 py-2">Nombre</th>
					<th class="border-2 text-center px-4 py-2">Teléfono</th>
					<th class="border-2 text-center px-4 py-2">Vendedor</th>
					<th class="border-2 text-center px-4 py-2">Total Bs</th>
					<th class="border-2 text-center px-4 py-2">Total $</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(Cotizacion, k) in getAllCotizaciones" :key="k">
					<td scope="row">
						<svg
							@click="deleteCotizacion(k)"
							class="hover:opacity-100 cursor-pointer fill-current text-red-500 opacity-75"
							width="26"
							height="26"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g id="delete_sweep_24px">
								<path
									id="icon/content/delete_sweep_24px"
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M6 4H10L11 5H14V7H2V5H5L6 4ZM5 20C3.89999 20 3 19.1 3 18V8H13V18C13 19.1 12.1 20 11 20H5ZM22 8H15V10H22V8ZM19 16H15V18H19V16ZM15 12H21V14H15V12ZM5 10H11V18H5V10Z"
								/>
							</g>
						</svg>
					</td>
					<td class="border-2 text-center px-4 py-4">
						<router-link
							class="hover:text-blue-500 hover:underline"
							:to="{
								name: 'Cotizacion',
								params: { id: Cotizacion.cotizacion_id }
							}"
							>{{ Cotizacion.cotizacion_id.padStart(4, "0") }}</router-link
						>
					</td>
					<td class="border-2 text-center px-4 py-4">
						{{ firstLetterUpperCase(Cotizacion.cliente_nombre) }}
					</td>
					<td class="border-2 text-center px-4 py-4">
						{{ Cotizacion.cliente_telefono }}
					</td>
					<td class="border-2 text-center px-4 py-1">
						{{ firstLetterUpperCase(Cotizacion.nombre_vendedor) }}
					</td>
					<td class="border-2 text-center px-4 py-4">
						{{ formato(Cotizacion.total_bs) }}
					</td>
					<td class="border-2 text-center px-4 py-4">
						{{ formato(Cotizacion.total_usd) }}
					</td>
				</tr>
			</tbody>
		</table>

		<div class="flex items-center justify-center w-full mb-6">
			<router-link to="/Cotizar"
				><Botonp class="underline" botonpText="Realizar Cotizacion"
					><svg
						id="Capa_1"
						enable-background="new 0 0 551.13 551.13"
						height="512"
						viewBox="0 0 551.13 551.13"
						width="512"
						class="h-6 w-6 mr-2 fill-current text-botonT"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
						/>
						<path
							d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
						/></svg></Botonp
			></router-link>
		</div>
	</div>
</template>

<script>
import mapState from "@vue/cli-plugin-vuex";
import Botonp from "@/components/botonPrimario";
import gql from "graphql-tag";
import { formato, firstLetterUpperCase } from "@/functions.js";
export default {
	name: "Cotizaciones",

	components: {
		Botonp
	},
	data: function() {
		return {
			carga: false,
			date: "",
			Cotizacioness: [],
			cotizacionId: [],
			unicoCotizacionId: [],
			numeroCliente: [],
			vendedores: [],
			matriz: [],
			tipoCambioConsulta: {},
			getAllCotizaciones: [],
			confirmarBorrar: false
		};
	},
	apollo: {
		getAllCotizaciones: {
			query: require("@/graphql/queries/CotizacionesQuery.gql"),
			fetchPolicy: "cache-and-network",
			update: data => data.getAllCotizaciones,
			error: (error, vm) => (vm.feedback.errorSearched = true),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		}
	},

	methods: {
		formato,
		firstLetterUpperCase,
		deleteRow(index) {
			if (this.confirmarBorrar) {
				this.getAllCotizaciones.splice(index, 1);
			}
		},

		async deleteCotizacion(index) {
			let cotiId;
			cotiId = this.getAllCotizaciones[index].cotizacion_id;
			this.confirmarBorrar = false;
			let opcion = confirm("¿Esta seguro de que borrara la cotización?");
			if (opcion) {
				this.confirmarBorrar = true;
			} else {
				this.confirmarBorrar = false;
			}
			this.deleteRow(index);
			if (this.confirmarBorrar) {
				if (cotiId != "") {
					this.$store.state.isLoading = true;
					await this.$apollo
						.mutate({
							mutation: gql`
								mutation($cotizacion_id: ID!) {
									eliminarCotizacion(cotizacion_id: $cotizacion_id) {
										__typename
									}
								}
							`,
							variables: {
								cotizacion_id: cotiId
							}
						})
						.then(() => {
							this.$store.state.isLoading = false;
						})
						.catch(error => {
							console.log(error);
							this.$store.state.isLoading = false;
						});
				}
				this.$store.state.isLoading = true;
				await this.$apollo
					.mutate({
						mutation: gql`
							mutation($cotizacion_id: String!) {
								borrarNotificacion(cotizacion_id: $cotizacion_id) {
									__typename
								}
							}
						`,
						variables: {
							cotizacion_id: cotiId
						}
					})
					.then(() => {
						this.$store.state.isLoading = false;
					})
					.catch(error => {
						console.log(error);
						this.$store.state.isLoading = false;
					});
			}
		}
	}
};
</script>
